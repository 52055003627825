<template>
  <div>
    <vue-headful :title="pageTitle" />
    <section>
      <div v-if="isLoading">
        <Loading />
      </div>
      <div v-else id="body-content-area-all">
        <div class="card">
          <div
            class="has-text-centered heading"
            style="background-color: #ededed; margin-bottom: 25px"
          >
            <h1 class="is-size-6" style="padding: 10px 0px">
              {{ pageheading.toLocaleUpperCase() }}
            </h1>
          </div>
          <div id="pagelayout">
            <div class="columns">
              <div class="column is-three-fifths">
                <form @submit.prevent="handleSubmit">
                  <div class="field">
                    <label class="label is-size-7"
                      >Nature of service request</label
                    >
                    <div class="control">
                      <div class="select">
                        <select v-model="input.type">
                          <option
                            v-for="opt in options"
                            :value="opt.value"
                            v-bind:key="opt.name"
                          >
                            {{ opt.name }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div class="field">
                    <label class="label is-size-7">Enter your message</label>
                    <div class="control">
                      <textarea
                        class="textarea"
                        v-model="inputMessage"
                        placeholder="How can we help you?"
                      ></textarea>
                    </div>
                  </div>

                  <div class="field is-pulled-left">
                    <div class="control">
                      <button
                        type="submit"
                        :disabled="inputMessage.trim().length <= 1"
                        class="button is-accent has-text-white"
                      >
                        Send Request
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Loading from './Loading'
import { mapState } from 'vuex'
import { activeSite } from '../vuex-actions'

let pageName = 'Service Request'

export default {
  components: {
    Loading
  },
  data () {
    return {
      input: {
        type: 'General Request'
      },
      options: [],
      isLoading: false,
      institution: null,
      pageheading: pageName
    }
  },
  computed: {
    ...mapState(['clientSession', activeSite]),
    pageTitle () {
      if (this.institution) {
        return (
          'Service Request - ' +
          this.institution.name +
          ' ' +
          (parseInt(this.activeSite.id) === 1 ? 'BOLI Portal' : 'COLI Portal')
        )
      }

      return ''
    },
    inputMessage: {
      get () {
        return this.$store.state.serviceRequest
      },
      set (value) {
        this.$store.commit('updateServiceRequest', value)
        this.input.message = this.inputMessage
      }
    }
  },
  async created () {
    this.getRequestOptions()
    this.GetInstitution()
  },
  methods: {
    async GetInstitution () {
      try {
        let that = this
        this.isLoading = true
        let route = `/institutions/${this.clientSession.institutionId}/`
        this.axios.get(route).then(function (response) {
          if (response.status >= 200 && response.status < 300) {
            that.institution = response.data
          }
        })
      } catch (error) {
        this.handleApiErr(error)
      }
    },
    resetForm () {
      this.inputMessage = ''
      this.input.type = 'General Request'
    },
    async getRequestOptions () {
      try {
        this.isLoading = true
        let route = '/service-request'
        let response = await this.axios.options(route)
        this.options = response.data
      } catch (error) {
        this.handleApiErr(error)
      } finally {
        this.isLoading = false
      }
    },
    async handleSubmit () {
      try {
        this.isLoading = true
        let route = `/service-request`
        let payload = { ...this.input }
        payload.message = this.inputMessage // Just in case it wasn't caught earlier.
        await this.axios.post(route, payload)
        this.resetForm()
        this.successToast(
          'Thank you for your message. Someone will contact you soon.'
        )
      } catch (error) {
        this.handleApiErr(error)
      } finally {
        this.isLoading = false
      }
    }
  }
}
</script>

<style scoped>
#pagelayout {
  width: 100%;
  padding: 25px;
}

.card {
  height: 100%;
}

#app-body-area[activelayout="client-facing"] #body-content-area-all {
  height: calc(100vh - 86px - 68px)!important;
  margin: 0 auto;
  width: 100%!important;
  padding: 1rem!important;
}

.modal-background {
  z-index: 40;
}

.modal-card {
  z-index: 50;
}
#app-body-area[activelayout="osr-facing"] #body-content-area-all {
  height: calc(100vh - 52px - 86px - 78px)!important;
  margin: 0 auto;
  width: 100%!important;
  padding: 1rem!important;
}

</style>
